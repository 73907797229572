import { lazy } from 'react';

export const Login = lazy(() => import('../pages/Login'));
export const ResetPassword = lazy(() => import('../pages/ResetPassword'));
export const Imprint = lazy(() => import('../pages/Imprint'));
export const DataProtection = lazy(() => import('../pages/DataProtection'));
export const Dashboard = lazy(() => import('../pages/Dashboard'));
export const Employees = lazy(() => import('../pages/Employees'));
export const EmployeeDetails = lazy(() => import('../pages/EmployeeDetails'));
export const EditEmployee = lazy(() => import('../pages/EditEmployee'));
export const CreateEmployee = lazy(() => import('../pages/CreateEmployee'));
export const Areas = lazy(() => import('../pages/Areas'));
export const AreasAddressesList = lazy(() => import('../pages/AreasAddressesList'));
export const AreaDetails = lazy(() => import('../pages/AreaDetails'));
export const AddressDetails = lazy(() => import('../pages/AddressDetails'));
export const EditArea = lazy(() => import('../pages/EditArea'));
export const CreateArea = lazy(() => import('../pages/CreateArea'));
export const Area = lazy(() => import('../pages/Areas'));
export const Files = lazy(() => import('../pages/Files'));
export const FileDetails = lazy(() => import('../pages/FileDetails'));
export const Projects = lazy(() => import('../pages/Projects'));
export const ProjectDetails = lazy(() => import('../pages/ProjectDetails'));
export const ProjectLeads = lazy(() => import('../pages/ProjectLeads'));
export const ProjectContracts = lazy(() => import('../pages/ProjectContracts'));
export const CreateProject = lazy(() => import('../pages/CreateProject'));
export const EditProject = lazy(() => import('../pages/EditProject'));
export const Calender = lazy(() => import('../pages/Calender'));
export const EditAppointment = lazy(() => import('../pages/EditAppointment'));
export const AppointmentDetails = lazy(() => import('../pages/AppointmentDetails'));
export const CreateAppointment = lazy(() => import('../pages/CreateAppointment'));
export const NoMatch = lazy(() => import('../pages/NoMatch'));
