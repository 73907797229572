import React from "react";
import { Spinner } from "react-bootstrap";
import './StyledSpinner.scss'

const SPINNER_VARIANT = {
    Spinnertable: "spinner-table",
    Spinnerdiv: "spinner-div",
    SpinnerSmalldiv: "spinner-small-div",
    SpinnerSearch: "spinner-search",
    SpinnerButton: "spinner-button",
};

const StyledSpinner = ({
    variant = "spinner-table",
    size = 32,
    colSpan,
    style = {},
    colorVariant = "secondary",
}) => {
    const spinnerProps = {
        animation: "border",
        variant: colorVariant ? colorVariant : "secondary",
        style: { width: `${size}px`, height: `${size}px` },
    };

    switch (variant) {
        case SPINNER_VARIANT.Spinnertable:
            return (
                <tr className="loader-tr">
                    <td className="p-0" colSpan={colSpan}>
                        <div className="spinner-block">
                            <Spinner {...spinnerProps} />
                        </div>
                    </td>
                </tr>
            );

        case SPINNER_VARIANT.Spinnerdiv:
        case SPINNER_VARIANT.SpinnerSmalldiv:
        case SPINNER_VARIANT.SpinnerSearch:
            return (
                <div className={variant} style={style}>
                    <Spinner {...spinnerProps} />
                </div>
            );

        case SPINNER_VARIANT.SpinnerButton:
            return (
                <tr className="loader-tr">
                    <td className="p-0" colSpan={colSpan}>
                        <div className="spinner-block">
                            <Spinner {...spinnerProps} variant="light" />
                        </div>
                    </td>
                </tr>
            );

        default:
            return null;
    }
}

export default StyledSpinner;
