import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { toast } from 'react-toastify';

export const fileUploadSlice = createSlice({
    name: 'fileUpload',
    initialState: {
        fileUploadResponse: {
            message: null,
            status: null,
        },
        addressFileUploadResponse: {
            message: null,
            status: null,
        },
        isFileUploaded: false,
        isFileUploading: false,
        isAddressesUploading: false,
        isAddressesUploaded: false,
    },
    reducers: {
        setIsFileUploaded: (state, action) => {
            state.isFileUploaded = action.payload;
        },
        setIsAddressFileUploaded: (state, action) => {
            state.isAddressesUploaded = action.payload;
        },
        setIsFileUploading: (state, action) => {
            state.isFileUploading = action.payload;
        },
        setIsAddressesUploading: (state, action) => {
            state.isAddressesUploading = action.payload;
        },
        setFileUploadResponse: (state, action) => {
            state.fileUploadResponse = action.payload;
        },
        setAddressFileUploadResponse: (state, action) => {
            state.addressFileUploadResponse = action.payload;
        },
        resetIsFileUploaded: (state, action) => {
            state.isFileUploaded = false;
        },
        resetIsAddressFileUploaded: (state, action) => {
            state.isAddressesUploaded = false;
        },
        resetFileUploadResponse: (state, action) => {
            state.fileUploadResponse = {
                message: null,
                status: null,
                areaUid: null,
            };
        },
        resetAddressFileUploadResponse: (state, action) => {
            state.addressFileUploadResponse = {
                message: null,
                status: null,
                areaUid: null,
            };
        },
    },
});

export const uploadAddresses = (bodyData) => async (dispatch) => {
    try {
        dispatch(setIsAddressesUploading(true));
        alert("While addresses uploading don't refresh or close page.")
        const url = `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/handleAddAddresses`;
        // const url = `http://127.0.0.1:5001/elamana-sales-app/us-central1/handleAddAddresses`;
        const response = await axios.post(url, bodyData)
        if (response.status === 200) {
            dispatch(setAddressFileUploadResponse({ ...response?.data, status: 200 }));
            dispatch(setIsAddressFileUploaded(true))
            dispatch(setIsAddressesUploading(false));
        } else {
            toast.warning(response?.data?.message || "Fehler beim Hinzufügen von Adressen!");
            dispatch(setIsAddressesUploading(false));
            dispatch(setIsAddressFileUploaded(false))
        }
    } catch (error) {
        console.error("error: ", error)
        toast.error("Fehler beim Hinzufügen von Adressen!");
        dispatch(setIsAddressesUploading(false));
        dispatch(setIsAddressFileUploaded(false))
    }
}

export const uploadFile = (bodyData) => async (dispatch) => {
    try {
        dispatch(setIsFileUploading(true));
        alert("While addresses uploading don't refresh page or close page.")
        const url = `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/handleFileUploadForArea`;
        // const url = `http://127.0.0.1:5001/elamana-sales-app/us-central1/handleFileUploadForArea`;
        const response = await axios.post(url, bodyData)
        if (response.status === 200) {
            dispatch(setFileUploadResponse({ ...response?.data, status: 200, areaUid: bodyData?.areaData?.uid }));
            dispatch(setIsFileUploaded(true))
            dispatch(setIsFileUploading(false));
        } else {
            toast.error("Fehler beim Hinzufügen von Adressen!");
            dispatch(setIsFileUploading(false));
            dispatch(setIsFileUploaded(false))
        }
    } catch (error) {
        console.error("error: ", error)
        toast.error("Fehler beim Hinzufügen von Adressen!");
        dispatch(setIsFileUploading(false));
        dispatch(setIsFileUploaded(false))
    }
};

export const {
    setFileUploadResponse,
    resetFileUploadResponse,
    setIsFileUploading,
    setIsFileUploaded,
    resetIsFileUploaded,
    setIsAddressesUploading,
    setIsAddressFileUploaded,
    resetIsAddressFileUploaded,
    setAddressFileUploadResponse,
    resetAddressFileUploadResponse,
} = fileUploadSlice.actions;

export default fileUploadSlice.reducer