import { ReactComponent as BackIcon } from '../assets/icons/back-arrow.svg';
import { ReactComponent as LeftIcon } from '../assets/icons/left.svg'
import { ReactComponent as RightIcon } from '../assets/icons/right.svg'
import { ReactComponent as WhiteRightIcon } from '../assets/icons/white-right-arrow.svg'
import { ReactComponent as PlusIcon } from '../assets/icons/add-icon.svg'
import { ReactComponent as RemoveIcon } from '../assets/icons/cross-btn-icon.svg';
import { ReactComponent as MoreIcon } from '../assets/icons/more-icon.svg';
import { ReactComponent as DeleteIcon } from '../assets/icons/delete-icon.svg';
import { ReactComponent as SearchIcon } from '../assets/icons/search.svg';
import { ReactComponent as ClearIcon } from '../assets/icons/close.svg';
import { ReactComponent as Strom } from '../assets/project-icons/strom.svg'
import { ReactComponent as Sonne } from '../assets/project-icons/sonne.svg'
import { ReactComponent as Internet } from '../assets/project-icons/internet.svg'
import { ReactComponent as Schild } from '../assets/project-icons/schild.svg'
import { ReactComponent as Haus } from '../assets/project-icons/haus.svg'
import { ReactComponent as Stadt } from '../assets/project-icons/stadt.svg'
import { ReactComponent as Schloss } from '../assets/project-icons/schloss.svg'
import { ReactComponent as EmployeeLogo } from "../assets/icons/employee.svg";
import { ReactComponent as TerritoriesLogo } from "../assets/icons/territories.svg";
import { ReactComponent as ChatLogo } from "../assets/icons/chat.svg";
import { ReactComponent as FileLogo } from "../assets/icons/files.svg";
import { ReactComponent as ProjectLogo } from "../assets/icons/projects.svg";
import { ReactComponent as CalenderLogo } from "../assets/icons/calender.svg";
import { ReactComponent as DownIcon } from '../assets/icons/down-icon.svg';
import { ReactComponent as DashIcon } from '../assets/icons/dash.svg'
import { ReactComponent as DownloadIcon } from '../assets/icons/download-icon.svg';
import { ReactComponent as UploadIcon } from '../assets/icons/upload-icon.svg';
import { ReactComponent as SortingIcon } from '../assets/icons/soting-icon.svg';
import { ReactComponent as RightArrow } from '../assets/icons/right-arrow.svg';
import { ReactComponent as TerritoriesIcon } from '../assets/icons/territories.svg';
import { ReactComponent as ProjectIcon } from '../assets/icons/projects.svg';
import { ReactComponent as EmployeeIcon } from '../assets/icons/employee.svg';
import { ReactComponent as LogOutIcon } from '../assets/icons/logout-icon.svg';
import { ReactComponent as UpArrowIcon } from '../assets/icons/up-arrow-icon.svg';
import { ReactComponent as DownArrowIcon } from '../assets/icons/down-arrow-icon.svg';
import { ReactComponent as Logo } from "../assets/icons/logo-icon.svg";
import { ReactComponent as Logo2 } from '../assets/icons/logo-icon2.svg';
import { ReactComponent as ExportIcon } from '../assets/icons/export-icon.svg'
import { ReactComponent as FolderIcon } from '../assets/icons/folder-icon.svg'
import { ReactComponent as PngIcon } from '../assets/icons/png-icon.svg'
import { ReactComponent as PdfIcon } from '../assets/icons/pdf-icon.svg'
import { ReactComponent as ExlIcon } from '../assets/icons/exl-icon.svg'
import { ReactComponent as InfoIcon } from '../assets/icons/info.svg'
import { ReactComponent as TickIcon } from '../assets/icons/circle-check-solid.svg'
import { ReactComponent as CrossIcon } from '../assets/icons/circle-xmark-solid.svg'
import { ReactComponent as GreyLocationMarker } from '../assets/icons/grey-location-marker.svg'
import { ReactComponent as LeftButtonIcon } from '../assets/icons/left-button-icon.svg'
import { ReactComponent as Exclamation } from '../assets/icons/exclamation.svg';


export const SVGICON = {
    Logo: <Logo />,
    Logo2: <Logo2 />,
    BackIcon: <BackIcon />,
    LeftIcon: <LeftIcon />,
    RightIcon: <RightIcon />,
    PlusIcon: <PlusIcon />,
    RemoveIcon: <RemoveIcon />,
    DeleteIcon: <DeleteIcon />,
    MoreIcon: <MoreIcon />,
    SearchIcon: <SearchIcon />,
    ClearIcon: <ClearIcon />,
    Strom: <Strom />,
    Sonne: <Sonne />,
    Internet: <Internet />,
    Schild: <Schild />,
    Haus: <Haus />,
    Stadt: <Stadt />,
    Schloss: <Schloss />,
    EmployeeLogo: <EmployeeLogo />,
    TerritoriesLogo: <TerritoriesLogo />,
    ChatLogo: <ChatLogo />,
    FileLogo: <FileLogo />,
    ProjectLogo: <ProjectLogo />,
    CalenderLogo: <CalenderLogo />,
    DownIcon: <DownIcon />,
    DashIcon: <DashIcon />,
    DownloadIcon: <DownloadIcon />,
    UploadIcon: <UploadIcon />,
    SortingIcon: <SortingIcon />,
    RightArrow: <RightArrow />,
    TerritoriesIcon: <TerritoriesIcon />,
    ProjectIcon: <ProjectIcon />,
    EmployeeIcon: <EmployeeIcon />,
    LogOutIcon: <LogOutIcon />,
    UpArrowIcon: <UpArrowIcon />,
    DownArrowIcon: <DownArrowIcon />,
    ExportIcon: <ExportIcon />,
    FolderIcon: <FolderIcon />,
    PngIcon: <PngIcon />,
    PdfIcon: <PdfIcon />,
    ExlIcon: <ExlIcon />,
    InfoIcon: <InfoIcon />,
    TickIcon: <TickIcon />,
    CrossIcon: <CrossIcon />,
    GreyLocationMarker: <GreyLocationMarker />,
    LeftButtonIcon: <LeftButtonIcon />,
    Exclamation: <Exclamation />,
    WhiteRightIcon: <WhiteRightIcon />,
}