import URLS from "../routes/urls";
import { SVGICON } from "./icons";

export const ADMIN_SIDEBAR_ITEMS = [
    {
        text: "Employess",
        link: URLS.Employees,
        links: [URLS.Employees, URLS.EmployeeDetails, URLS.EditEmployee, URLS.CreateEmployee],
        icon: SVGICON.EmployeeIcon,
    },
    {
        text: "Areas",
        link: URLS.Areas,
        links: [URLS.Areas, URLS.AreaDetails, URLS.EditArea, URLS.CreateArea],
        icon: SVGICON.TerritoriesIcon,
    },
    {
        text: "Files",
        link: URLS.Files,
        links: [URLS.Files, URLS.FilesDetails],
        icon: SVGICON.FileLogo,
    },
    {
        text: "Projects",
        link: URLS.Projects,
        links: [URLS.Projects, URLS.ProjectDetails, URLS.CreateProject],
        icon: SVGICON.ProjectLogo,
    },
    {
        text: "Calender",
        link: URLS.Calender,
        links: [URLS.Calender],
        icon: SVGICON.CalenderLogo,
    },
]