import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetAddressFileUploadResponse, resetFileUploadResponse, resetIsAddressFileUploaded, resetIsFileUploaded } from '../../reduxSlice/fileUploadSlice';
import URLS from '../../routes/urls';
import { firestoreService } from '../../Firebase/firebaseConfig';
import { COLLECTION } from '../../constants/firebase-colloection';
import moment from 'moment';
import { timestampToDate } from '../../utils';
import { exportToXLSX } from '../../Firebase/apiService';
import CustomButton from '../CustomButton';
import { SVGICON } from '../../constants/icons';
import "./AddressprocessTile.scss";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const AddressProcessTile = () => {
    const location = useLocation();
    const isFileUploaded = useSelector((state) => state.fileUpload.isFileUploaded);
    const isAddressesUploaded = useSelector((state) => state.fileUpload.isAddressesUploaded);
    const isAddressesUploading = useSelector((state) => state.fileUpload.isAddressesUploading);
    const isFileUploading = useSelector((state) => state.fileUpload.isFileUploading);
    const fileUploadResponse = useSelector((state) => state.fileUpload.fileUploadResponse);
    const addressFileUploadResponse = useSelector((state) => state.fileUpload.addressFileUploadResponse);
    const [isLoading, setIsLoading] = useState({
        fileDownloading: false,
        isUnknownFileDownloading: false,
        isUnassignFileDownloading: false,
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleReviewClick = () => {
        if (fileUploadResponse?.areaUid) {
            navigate(`${URLS.AreaDetails}/${fileUploadResponse?.areaUid}`);
            dispatch(resetFileUploadResponse());
            dispatch(resetIsFileUploaded());
        }
    }

    const clearMessage = (isAddress = false) => {
        if (isAddress === true) {
            dispatch(resetIsAddressFileUploaded());
            dispatch(resetAddressFileUploadResponse());
        }
        else {
            dispatch(resetIsFileUploaded());
            dispatch(resetFileUploadResponse());
        }
    }

    const handleDownloadAddress = async () => {
        try {
            const fileName = 'unknown_addresses_data.xlsx';
            setIsLoading((prev) => ({ ...prev, fileDownloading: true }));
            let addressQuery = { field: "area_ref", operator: "==", value: firestoreService.getDocumentReference(COLLECTION.Areas, fileUploadResponse?.areaUid) };
            let addressQuery2 = { field: "latLng", operator: "==", value: null };
            const addresses = await firestoreService.findDocuments(COLLECTION.Addresses, addressQuery, addressQuery2);
            const areaSheetData = addresses?.map(({ uid, assigned_employee, number_commercial_units, number_residential_units, assignment_date, postal_code, street, house_number, gnv_available, WoWi }) => {
                return {
                    uid: uid,
                    assigned_employee: assigned_employee?.map((employee) => employee?.id).join(","),
                    assignment_date: assignment_date ? moment(timestampToDate(assignment_date)).format("DD/MM/YYYY") : null,
                    postal_code,
                    street,
                    house_number,
                    number_commercial_units,
                    number_residential_units,
                    gnv_available: gnv_available === true ? "1" : "0",
                    WoWi,
                }
            });
            exportToXLSX(areaSheetData, fileName);
            setIsLoading((prev) => ({ ...prev, fileDownloading: false }));
            clearMessage();
        } catch (error) {
            console.error(error)
            setIsLoading((prev) => ({ ...prev, fileDownloading: false }));

        }
    }

    const handleUnassignClick = () => {
        setIsLoading((prev) => ({ ...prev, isUnassignFileDownloading: false }));
        const fileName = 'conflicted_addresses_data.xlsx';
        const areaSheetData = addressFileUploadResponse?.unAssignAddresses?.map(({ area_ref, assigned_employee, number_commercial_units, number_residential_units, assignment_date, postal_code, street, house_number, gnv_available, WoWi }) => {
            return {
                area_ref: !!area_ref?.length ? area_ref : null,
                assigned_employee: !!assigned_employee?.length ? assigned_employee?.map((employee) => employee?.id).join(",") : null,
                assignment_date: assignment_date ? moment(timestampToDate(assignment_date)).format("DD/MM/YYYY") : null,
                postal_code,
                street,
                house_number,
                number_commercial_units,
                number_residential_units,
                gnv_available: gnv_available === true ? "1" : "0",
                WoWi,
            }
        });
        exportToXLSX(areaSheetData, fileName);
        setIsLoading((prev) => ({ ...prev, isUnassignFileDownloading: false }));
    };

    const handleUnknownClick = () => {
        setIsLoading((prev) => ({ ...prev, isUnknownFileDownloading: false }));
        const fileName = 'unknown_addresses_data.xlsx';
        const areaSheetData = addressFileUploadResponse?.unknownAddresses?.map(({ assigned_employee, number_commercial_units, number_residential_units, assignment_date, postal_code, street, house_number, gnv_available, WoWi }) => {
            return {
                assigned_employee: !!assigned_employee?.length ? assigned_employee?.map((employee) => employee?.id).join(",") : null,
                assignment_date: assignment_date ? moment(timestampToDate(assignment_date)).format("DD/MM/YYYY") : "",
                postal_code,
                street,
                house_number,
                number_commercial_units,
                number_residential_units,
                gnv_available: gnv_available === true ? "1" : "0",
                WoWi,
            }
        });
        exportToXLSX(areaSheetData, fileName);
        setIsLoading((prev) => ({ ...prev, isUnknownFileDownloading: false }));
    };

    const isAreaDetailsPage = location.pathname.split("/").includes("area-details") && location.pathname.split("/").includes(fileUploadResponse?.areaUid);

    return (
        <div>
            {(isFileUploading || isAddressesUploading) ?
                <div className='address-loading'>
                    <div className='tile'>
                        Adressen werden hochgeladen...
                    </div>
                </div> : null}
            {isAddressesUploaded ? <div className='address-loading-tile'>
                <div className="tile">
                    <div className="count-details">
                        <div className='success-message'>
                            {addressFileUploadResponse?.message || "Adressen erfolgreich hinzugefügt!"}
                        </div>
                        {!!addressFileUploadResponse?.totalAddresses ? <div className="count">
                            Gesamtadresse: {addressFileUploadResponse?.totalAddresses}
                        </div> : null}
                        {!!addressFileUploadResponse?.successfullyAddresses ? <div className="count">
                            Erfolgreich hochgeladene Adresse: {addressFileUploadResponse?.successfullyAddresses || 0}
                        </div> : null}
                        {!!addressFileUploadResponse?.unknownAddresses?.length ? <div className="count">
                            Unbekannte Adresse: {addressFileUploadResponse?.unknownAddresses?.length || 0}
                        </div> : null}
                        {!!addressFileUploadResponse?.unAssignAddresses?.length ? <div className="count">
                            Konfliktadresse: {addressFileUploadResponse?.unAssignAddresses?.length || 0}
                        </div> : null}
                    </div>
                    <div className="buttons">
                        {addressFileUploadResponse?.unknownAddresses?.length ?
                            <div className='button-tooltip'>
                                <OverlayTrigger
                                    className="step-overlay"
                                    placement="left"
                                    overlay={
                                        <Tooltip className="step-tooltip">
                                            <div className="custom-step-tooltip">
                                                <strong>Schritt zum Auflösen einer unbekannten Adresse</strong>
                                                <ul className='tooltip-list'>
                                                    <li>Klicken Sie auf die Schaltfläche „Herunterladen“, um das Blatt mit den unbekannten Adressen zu erhalten.</li>
                                                    <li>Geben Sie gültige Adressen ein und überprüfen Sie Hausnummern und Postleitzahlen.</li>
                                                    <li>Aktualisieren Sie das Blatt mit den unbekannten Adressen mit den korrekten Informationen.</li>
                                                    <li>Laden Sie das Blatt mit den unbekannten Adressen erneut hoch.</li>
                                                </ul>
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <i>
                                        {SVGICON.InfoIcon}
                                    </i>
                                </OverlayTrigger>
                                <CustomButton
                                    text={"Unbekannte Adresse herunterladen"}
                                    className={"edit-btn"}
                                    onClick={handleUnknownClick}
                                    isLoading={isLoading.isUnknownFileDownloading}
                                >
                                    {SVGICON.DownloadIcon}
                                </CustomButton>
                            </div>
                            : null}
                        {addressFileUploadResponse?.unAssignAddresses?.length ?
                            <div className='button-tooltip'>
                                <OverlayTrigger
                                    placement="left"
                                    overlay={
                                        <Tooltip className="step-tooltip">
                                            <div className="custom-step-tooltip">
                                                <strong>Schritt zur Lösung von Konfliktadressen</strong>
                                                <ul className='tooltip-list'>
                                                    <li>Klicken Sie auf die Schaltfläche „Herunterladen“, um das Blatt mit den in Konflikt stehenden Adressen zu erhalten.</li>
                                                    <li>Geben Sie eine gültige Gebiets-UID aus dem Blatt „Gebiets-UID“ ein.</li>
                                                    <li>Aktualisieren Sie das Konfliktblatt mit der richtigen Bereichs-UID.</li>
                                                    <li>Laden Sie das in Konflikt stehende Blatt erneut hoch.</li>
                                                </ul>
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <i>
                                        {SVGICON.InfoIcon}
                                    </i>
                                </OverlayTrigger>
                                <CustomButton
                                    text={"Konfliktadresse herunterladen"}
                                    className={"edit-btn"}
                                    onClick={handleUnassignClick}
                                    isLoading={isLoading.isUnassignFileDownloading}
                                >
                                    {SVGICON.DownloadIcon}
                                </CustomButton>
                            </div>
                            : null}
                        <CustomButton
                            text={"Close"}
                            className={"edit-btn close-btn"}
                            onClick={() => clearMessage(true)}
                            isLoading={isLoading.isUnknownFileDownloading}
                        >
                        </CustomButton>
                    </div>
                </div>
            </div> : null}
            {isFileUploaded ? <div className='address-loading-tile'>
                <div className="tile">
                    <div className="count-details">
                        <div className='success-message'>
                            Adressen erfolgreich hinzugefügt!
                        </div>
                        {!!fileUploadResponse?.totalAddresses ? <div className="count">
                            Gesamtadresse: {fileUploadResponse?.totalAddresses}
                        </div> : null}
                        {!!fileUploadResponse?.successfullyAddresses ? <div className="count">
                            Erfolgreich hochgeladene Adresse: {fileUploadResponse?.successfullyAddresses || 0}
                        </div> : null}
                        {!!fileUploadResponse?.unknownAddresses ? <div className="count">
                            Unbekannte Adresse: {fileUploadResponse?.unknownAddresses || 0}
                        </div> : null}
                        {!!fileUploadResponse?.unAssignAddresses ? <div className="count">
                            Adresszuweisung aufheben: {fileUploadResponse?.unAssignAddresses || 0}
                        </div> : null}
                    </div>
                    <div className="buttons">
                        <div className='button-tooltip'>
                            <OverlayTrigger
                                className="step-overlay"
                                placement="left"
                                overlay={
                                    <Tooltip className="step-tooltip">
                                        <div className="custom-step-tooltip">
                                            <strong>Schritt zum Auflösen einer unbekannten Adresse</strong>
                                            <ul className='tooltip-list'>
                                                <li>Klicken Sie auf die Schaltfläche „Herunterladen“, um das Blatt mit den unbekannten Adressen zu erhalten.</li>
                                                <li>Geben Sie gültige Adressen ein und überprüfen Sie Hausnummern und Postleitzahlen.</li>
                                                <li>Aktualisieren Sie das Blatt mit den unbekannten Adressen mit den korrekten Informationen.</li>
                                                <li>Laden Sie das Blatt mit den unbekannten Adressen erneut hoch.</li>
                                            </ul>
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <i>
                                    {SVGICON.InfoIcon}
                                </i>
                            </OverlayTrigger>
                            <CustomButton
                                text={fileUploadResponse?.unknownAddresses ? "Unbekannte Adressen herunterladen" : isAreaDetailsPage ? "Schließen" : "Gebietsangaben"}
                                className={isAreaDetailsPage ? "edit-btn close-btn" : "edit-btn"}
                                onClick={fileUploadResponse?.unknownAddresses ? handleDownloadAddress : isAreaDetailsPage ? clearMessage : handleReviewClick}
                                isLoading={isLoading.fileDownloading}
                            >
                                {fileUploadResponse?.unknownAddresses ? SVGICON.DownloadIcon : !isAreaDetailsPage ? SVGICON.LeftButtonIcon : null}
                            </CustomButton>
                        </div>
                    </div>
                </div>
            </div> : null}
        </div>
    )
}

export default AddressProcessTile;