import React from "react";
import StyledSpinner from "../StyledSpinner"; // Import your styled Spinner component

const FullPageSpinner = () => {
    return (
        <div
            style={{
                height: "100vh",
                width: "100vw",
                position: "fixed",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                top: "0",
                background: "#ffffff",
                zIndex: "100",
            }}
        >
            <StyledSpinner variant="spinner-div" size={50} />
        </div>
    );
}

export default FullPageSpinner;
