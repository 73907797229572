import { COLLECTION } from "../constants/firebase-colloection";
import { firestoreService, storageServices } from "./firebaseConfig";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { getStorage, ref } from "firebase/storage";

export async function DownloadFile(fileLink, fileName) {
    saveAs(fileLink, fileName);
}

export function exportToXLSX(data, filename) {
    // Create a worksheet from the data
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Create a new workbook and add the worksheet to it
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Enquiries');
    
    // Write the workbook data to an array buffer
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Create a Blob from the array buffer
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Trigger the download using saveAs

    saveAs(blob, filename);
}

export function importFromXLSX(file, isHeaderReturn = false) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.onload = (e) => {
            const data = new Uint8Array(e?.target?.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false });
            const formattedData = jsonData?.slice(1).map((row) => {
                const obj = {};
                jsonData[0]?.forEach((key, index) => {
                    obj[key] = row[index];
                });
                return obj;
            });
            resolve(isHeaderReturn ? { formattedData, headers: headers[0] } : formattedData);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
        fileReader.readAsArrayBuffer(file);
    });
}

export const deleteProjectWithReference = async (projectId, roomId, folderId) => {
    try {
        // delete assign area
        const areaList = await firestoreService.getAllDocuments(COLLECTION.Areas);

        areaList.forEach((_area) => {
            _area.assigned_projects = _area.assigned_projects.filter((value) => value?.id !== projectId);
        });
        await firestoreService.updateBatchDocs(COLLECTION.Areas, areaList);

        // delete rooms
        await firestoreService.deleteDocument(COLLECTION.Rooms, roomId);

        // delete folder collection 
        let folders = await firestoreService.getAllDocuments(COLLECTION.Folders(projectId))
        await firestoreService.deleteDocuments(COLLECTION.Folders(projectId), folders);

        // delete folder
        const storage = getStorage();
        const folderRef = ref(storage, `files/${folderId}`);
        await storageServices.deleteFolderContentsAndFolder(folderRef);

        // delete project
        await firestoreService.deleteDocument(COLLECTION.Projects, projectId);
        return true;
    } catch (error) {
        console.error(error)
        return false;
    }
}

export const deleteAdressesWithoutAreaRef = async () => {
    firestoreService.getAllDocuments(COLLECTION.Addresses).then(async (addresses) => {
        addresses.map((address) => {
            if (!address.area_ref) {
                firestoreService.deleteDocument(COLLECTION.Addresses, address.uid);
            }
            return address;
        });
    });
}
export const deleteAdresses = async (area_ref) => {
    try {
        // Annahme: firestoreService.getDocumentReference ist eine asynchrone Funktion
        const _area_ref = await firestoreService.getDocumentReference(COLLECTION.Areas, area_ref);
        console.log(_area_ref.id);

        const addresses = await firestoreService.getAllDocuments(COLLECTION.Addresses);
        console.log(addresses[0].area_ref);

        const deletePromises = addresses
            .filter((address) => address.area_ref.id ===_area_ref.id)
            .map((address) => firestoreService.deleteDocument(COLLECTION.Addresses, address.uid));

        await Promise.all(deletePromises);

        console.log(`${deletePromises.length} Addresses deleted for area_ref: ${area_ref}`);
    } catch (error) {
        console.error('Error deleting addresses:', error.message);
    }
};


