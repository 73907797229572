import { GeoPoint, Timestamp } from "firebase/firestore";
import { firestoreService } from "../Firebase/firebaseConfig";
import bcrypt from 'bcryptjs';
import moment from "moment";
import { SVGICON } from "../constants/icons";

export const titleCase = (str, key = null) => {
    const txt = str?.replace(/_/g, " ")?.replace(/([A-Z])/g, "$1")
    const text = txt?.split(' ')
        ?.map(word => word.charAt(0)?.toUpperCase() + word?.slice(1))
        ?.join(' ');
    if (key === "And") {
        return text.replace("And", "&");
    }
    return text;
};

// Email Validation
export const validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const capitalize = (string) => {
    if (string) {
        let str = string.trim();
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
}

export function getInitials(name) {
    try {
        if (typeof name === 'string' && name.trim() !== '') {
            const firstName = name.trim().split(" ")[0];
            const firstLetter = firstName[0].toUpperCase();
            return firstLetter;
        }
        return "?"; // Return a question mark if the name is empty or not a string
    } catch (error) {
        return "?"; // Return a question mark in case of an error
    }
}

// localStorage actions

export function storage(action, key, data) {
    switch (action) {
        case 'create':
        case 'update':
            localStorage.setItem(key, JSON.stringify(data));
            break;
        case 'get':
            const storedData = localStorage.getItem(key);
            return storedData ? JSON.parse(storedData) : null;
        case 'delete':
            localStorage.removeItem(key);
            break;
        case 'clear':
            localStorage.clear();
            break;
        default:
            throw new Error('Invalid action specified.');
    }
};

// Function to convert a Firebase Timestamp to a JavaScript Date object
export function timestampToDate(firebaseTimestamp) {
    if (firebaseTimestamp instanceof Timestamp) {
        return firebaseTimestamp.toDate();
    } else if (typeof firebaseTimestamp === 'object' && (firebaseTimestamp.hasOwnProperty('_seconds') || firebaseTimestamp.hasOwnProperty('seconds'))) {
        return new Date(firebaseTimestamp._seconds * 1000 + (firebaseTimestamp._nanoseconds || 0) / 1000000);
    } else {
        throw new Error('Input is not a valid Firebase Timestamp');
    }
}

// Function to convert a JavaScript Date object to a Firebase Timestamp
export function dateToTimestamp(jsDate) {
    if (!(jsDate instanceof Date)) {
        throw new Error('Input is not a valid Date object');
    }

    return Timestamp.fromDate(jsDate);
}

export function areAllValuesFulfilled(obj) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (typeof value === 'string' && value.trim() === '') {
                return false; // Found an empty string, return false
            } else if (Array.isArray(value) && value.length === 0 || value === undefined || value === null) {
                return false; // Found an empty array, return false
            }
        }
    }
    return true; // All values are fulfilled, return true
}

export function formatFileSize(bytes) {
    if (bytes === 0)
        return '0 bytes';
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const k = 1024;
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + units[i];
}

export function getFileIcon(name) {
    let stringLength = name?.split(".");
    let type = stringLength[(stringLength.length - 1)]?.toLowerCase();
    switch (type) {
        case "png":
        case "jpg":
        case "jpeg":
            return SVGICON.PngIcon;
        case "pdf":
        case "docx":
        case "pptx":
            return SVGICON.PdfIcon;
        case "xlsx":
        case "exl":
            return SVGICON.ExlIcon;
        default:
            return SVGICON.FolderIcon;
    }
}

export function convertTimeToTimestamp(eventDay, time) {
    const [hours, minutes] = time.split(':');
    let date = moment(eventDay).format("MM/DD/YYYY");
    const now = new Date(date); // Get the current date
    now.setHours(hours, minutes, 0, 0); // Set the hours and minutes from the input
    return Timestamp.fromDate(now);
}

export function convertTimestampObjectToHHMM(timestampObject) {
    if (!timestampObject || typeof timestampObject !== 'object' || !timestampObject.seconds) {
        throw new Error("Invalid timestamp object.");
    }

    const timestampDate = new Date(timestampObject.seconds * 1000); // Convert seconds to milliseconds
    const hours = timestampDate.getHours();
    const minutes = timestampDate.getMinutes();

    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    return formattedTime;
}

// Function to format the date as DD.MM.YYYY
export function formatDate(dateString) {
    const dateObject = new Date(dateString);
    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getFullYear().toString();
    return `${day}.${month}.${year}`;
}

export function createQuery(CollectionName, field, value, operator = "array-contains-any") {
    return {
        field: field,
        operator: operator,
        value: [firestoreService.getDocumentReference(CollectionName, value)]
    };
}

// Function to encrypt a password
export const encryptPassword = async (password) => {
    try {
        const salt = await bcrypt.genSalt(10); // Generate a salt with a work factor of 10
        const hash = await bcrypt.hash(password, salt);
        return hash;
    } catch (error) {
        throw new Error('Error encrypting password');
    }
};

// Function to compare a password with a hashed password
export const comparePasswords = async (password, hashedPassword) => {
    try {
        const match = await bcrypt.compare(password, hashedPassword);
        return match;
    } catch (error) {
        throw new Error('Error comparing passwords');
    }
};

export function getMonthName(index) {
    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    return monthNames[index];
}

export function convertMonthToGerman(monthName) {
    const monthNames = {
        January: 'Januar',
        February: 'Februar',
        March: 'März',
        April: 'April',
        May: 'Mai',
        June: 'Juni',
        July: 'Juli',
        August: 'August',
        September: 'September',
        October: 'Oktober',
        November: 'November',
        December: 'Dezember',
    };

    // Check if the provided monthName is in English
    if (monthNames.hasOwnProperty(monthName)) {
        return monthNames[monthName];
    } else {
        // Return the original month name if it's not in the dictionary
        return monthName;
    }
}

export function convertDayToGerman(day) {
    const daysInEnglish = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const daysInGerman = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];

    // Find the index of the input day in the English array
    const index = daysInEnglish.indexOf(day);

    if (index !== -1) {
        // If the day is found in the English array, return the corresponding German day
        return daysInGerman[index];
    } else {
        // If the input day is not valid, return an error message or handle it as needed
        return "Invalid day";
    }
}

export function addEllipsis(text, maxLength) {
    if (text.length <= maxLength) {
        return text;
    } else {
        return `${text.substring(0, maxLength)}...`;
    }
}

export function addCountAbbreviation(inputList) {
    return inputList.reduce((acc, item) => {
        const street = item.street ?? "Unbekannte Straße";
        const areaCount = (acc.areaCounts[street] || 0) + 1;
        acc.areaCounts[street] = areaCount;
        let name = areaCount === 1 ? street : `${street} ${areaCount}`;
        acc.result.push({
            ...item,
            street_alias_name: name,
        });

        return acc;
    }, { areaCounts: {}, result: [] }).result;
}

export const calculateLatLong = async (address) => {
    var geocoder = new window.google.maps.Geocoder();
    var results = await geocoder.geocode({ 'address': address?.street + " " + address?.house_number + " " + address?.postal_code });

    if (results.results[0]) {
        var latitude = results.results[0].geometry.location.lat();
        var longitude = results.results[0].geometry.location.lng();
        return new GeoPoint(latitude, longitude);
    }
    console.error('Geocoding fehlgeschlagen. Status:', results ? results[0] : 'Unknown');
    return null;

}

export function calculateTotalHousehold(array) {
    if (array) {
        const { totalCommercialUnits, totalResidentialUnits } = array.reduce(
            (totals, item) => ({
                totalCommercialUnits: totals.totalCommercialUnits + parseInt(item.number_commercial_units),
                totalResidentialUnits: totals.totalResidentialUnits + parseInt(item.number_residential_units),
            }),
            { totalCommercialUnits: 0, totalResidentialUnits: 0 }
        );
        return totalCommercialUnits + totalResidentialUnits;
    } else {
        return 0;
    }
}

export function calculateTotalNotVisitedHousehold(array) {
    const { totalCount } = array.reduce((totals, item) => {
        let visitedHousehold = item?.household?.length;
        let household = parseInt(item?.number_commercial_units) + parseInt(item?.number_residential_units)
        return ({
            totalCount: totals.totalCount + (household - visitedHousehold),
        })
    }, { totalCount: 0 });

    return totalCount;
}

export function calculateTotalAvailableGnvs(array) {
    const { totalCount } = array.reduce((totals, item) => {
        let flag = item?.gnv_available;
        return ({
            totalCount: flag ? totals.totalCount + 1 : totals.totalCount,
        })
    }, { totalCount: 0 });
    return totalCount;
}

export function calculateAreaCount(area) {
    if (area) {
        let filteredConfirmAddresses = area?.address_ref?.filter((address) => !Boolean(address?.visits?.find((value) => value?.confirm_status === 1 && value?.visitNumber === 3)));
        return {
            address: filteredConfirmAddresses?.length || 0,
            household: calculateTotalHousehold(area?.address_ref) || 0,
            notVisitedHousehold: calculateTotalNotVisitedHousehold(area?.address_ref) || 0,
            totalCount: 0,
            availableGnvs: calculateTotalAvailableGnvs(area?.address_ref) || 0,
        }
    }
    return {
        address: 0,
        household: 0,
        notVisitedHousehold: 0,
        totalCount: 0,
        availableGnvs: 0,
    }
}

export function calculateVisitsCount(array, key = "area") {
    if (key === "area") {
        const { totalNotfound, totalNotInterested, totalContractConclusion, totalVisits } = array?.address_ref.reduce(
            (totals, item) => {
                const countMap = item.visits.reduce((acc, item) => {
                    let status = item.status;
                    acc[status] = (acc[status] || 0) + 1;
                    return acc;
                }, {});
                return ({
                    totalVisits: totals.totalVisits + (item.visits?.length || 0),
                    totalNotfound: totals.totalNotfound + (countMap["FoundNoOne"] || 0),
                    totalNotInterested: totals.totalNotInterested + (countMap["NoInterest"] || 0),
                    totalContractConclusion: totals.totalContractConclusion + (countMap["Contracted"] || 0),
                })
            },
            { totalNotfound: 0, totalNotInterested: 0, totalContractConclusion: 0, totalVisits: 0 }
        );
        return { totalNotfound, totalNotInterested, totalContractConclusion, totalVisits };
    } else if (key === "project") {
        const countMap = array.reduce((acc, item) => {
            let status = item.status;
            acc[status] = (acc[status] || 0) + 1;
            return acc;
        }, {});
        return ({
            totalVisits: (array?.length || 0),
            totalNotfound: (countMap["FoundNoOne"] || 0),
            totalNotInterested: (countMap["NoInterest"] || 0),
            totalContractConclusion: (countMap["Contracted"] || 0),
        });
    }
}

export function calculatePercentageValue(value, totalLength = 100) {
    if (value === 0) return 0;
    else if (!value) return 0;
    else if (!totalLength) return 0;
    else if (value === 0 && totalLength === 0) return 0;
    return Math.round((100 * value) / totalLength);
}

export function sortData(array, sortDirection, key = "name") {
    return array?.sort((a, b) => {
        const nameA = (a[key] ?? "").toLowerCase();
        const nameB = (b[key] ?? "").toLowerCase();

        if (sortDirection === 'asc') {
            return nameA.localeCompare(nameB);
        } else if (sortDirection === 'desc') {
            return nameB.localeCompare(nameA);
        } else {
            throw new Error('Invalid sort direction');
        }
    });
}

export function sortAreaData(array, sortDirection, key = "name") {
    if (array.filter((item) => item?.area_ref !== null || item?.area_ref !== undefined).length === 0) {
        return [];
    }
    return array.filter((item) => item?.area_ref !== null || item?.area_ref !== undefined).sort((a, b) => {
        console.log(a, b);
        const nameA = a.area_ref[key]?.toLowerCase();
        const nameB = b.area_ref[key]?.toLowerCase();

        if (sortDirection === 'asc') {
            return nameA.localeCompare(nameB);
        } else if (sortDirection === 'desc') {
            return nameB.localeCompare(nameA);
        } else {
            throw new Error('Invalid sort direction');
        }
    });
}

export function searchFromList(list, searchString, key = "name") {
    try {
        if (searchString.trim().length === 0)
            return list;
        const response = list.filter(item =>
            item[key]?.toLowerCase().includes(searchString?.trim()?.toLowerCase())
        );
        return response;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export function groupedData(data, key = "status", keyType = "regular") {
    return data.flat(2).reduce((result, item) => {
        const _key = keyType === "documentRef" ? item[key]?.id : item[key];
        if (!result[_key]) {
            result[_key] = [];
        }
        result[_key].push(item);
        return result;
    }, {});
}

export function arraysAreEqual(arr1, arr2) {
    return arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
}

export function dateStringToDateObject(dateString) {
    if (!(dateString instanceof Date)) {
        var dateParts = dateString.split(".");
        var day = parseInt(dateParts[0], 10);
        var month = parseInt(dateParts[1], 10) - 1;
        var year = parseInt(dateParts[2], 10);
        return new Date(year, month, day);
    }
    return dateString;
}

export function debounce(func, delay) {
    let timer;
    return function (...args) {
        clearTimeout(timer);
        timer = setTimeout(() => func.apply(this, args), delay);
    };
};

export function getObjectByKey(array, key, value) {
    return array?.find(item => item[key] === value) || null;
}

export function getUniqueList(array) {
    if (!!array.length)
        return Array.from(new Set(array?.map(user => user.uid))).map(uid => array?.find(user => user.uid === uid));
    else
        return [];
} 