export const COLLECTION = {
    Employees: 'employees',
    AdminUser: 'admin-user',
    Projects: 'projects',
    Folders: (uid) => `projects/${uid}/folders`,
    Household: (uid) => `addresses/${uid}/household`,
    Visits: (uid) => `addresses/${uid}/visits`,
    Areas: 'areas',
    Addresses: 'addresses',
    Rooms: 'rooms',
    Appointment: 'appointment',
    Leads: 'leads',
};