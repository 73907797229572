import React from 'react'
import './sidebar.scss'
import { Link, useLocation } from 'react-router-dom';
import URLS from '../../routes/urls';
import { SVGICON } from '../../constants/icons';
import AddressProcessTile from '../AddressProcessTile';

const Sidebar = ({ items }) => {
  const location = useLocation();

  return (
    <div>
      <AddressProcessTile />
      <div className="sidebar" expand="lg">
        <Link to={URLS.DashBoard} className={`sidebar-logo ${URLS.DashBoard === location.pathname ? "active" : null}`}>
          <i>{SVGICON.Logo}</i>
        </Link>
        {/* Icons */}
        <div className="sidebar-icons">
          {items.map((item, index) => {
            const isActive = item.links.some((path) => location.pathname.includes(path));
            return (<Link to={item.link} key={index} className={`sidebar-menu-list ${isActive ? "active" : null}`}>
              <i>{item.icon} </i> {/* pass icon props -> item.icon(iconProps) */}
            </Link>)
          })}
        </div>
      </div>
    </div>
  );
}

export default Sidebar
