import React, { Suspense, useEffect } from 'react'
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import './App.css'
import useAuth from './hooks/useAuth';
import * as PAGES from './routes/pages'
import Sidebar from './components/Sidebar';
import { ADMIN_SIDEBAR_ITEMS } from './constants/sidebar-items';
import URLS from './routes/urls';
import FullPageSpinner from './components/FullPageSpinner';
import { useSelector } from 'react-redux';

function App() {
  const location = useLocation();
  const isAuthenticated = useAuth();
  const isFileUploaded = useSelector((state) => state.fileUpload.isFileUploaded);
  const isAddressesUploaded = useSelector((state) => state.fileUpload.isAddressesUploaded);

  const PrivateWrapper = ({ auth: { isAuthenticated } }) => {
    return isAuthenticated ? <Outlet /> : <Navigate to={URLS.Login} />;
  };

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    };

    if (!window.google || !window.google.maps) {
      loadGoogleMapsScript();
    }
  }, []);

  // code to prevent reload and close tab 
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ''; // For Chrome
      return ''; // For Firefox
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <div className="app-wrapper">
      {(isAuthenticated && ![URLS.Login, URLS.Imprint, URLS.DataProtection].includes(location.pathname)) &&
        <Sidebar items={Object.values(ADMIN_SIDEBAR_ITEMS)} />}
      <div className={isFileUploaded ? 'content-wrapper mt-75' : isAddressesUploaded ? 'content-wrapper mt-100' : 'content-wrapper'}>
        <Routes>
          <Route path="/" element={<Navigate to={URLS.DashBoard} />} />

          <Route element={<PrivateWrapper auth={{ isAuthenticated }} />}>
            <Route path={URLS.DashBoard} element={
              <Suspense fallback={<FullPageSpinner />}>
                <PAGES.Dashboard />
              </Suspense>
            } />
            <Route path={URLS.Employees} element={
              <Suspense fallback={<FullPageSpinner />}>
                <PAGES.Employees />
              </Suspense>
            } />
            <Route path={`${URLS.EmployeeDetails}/:id`} element={
              <Suspense fallback={<FullPageSpinner />}>
                <PAGES.EmployeeDetails />
              </Suspense>
            } />
            <Route path={`${URLS.EditEmployee}/:id`} element={
              <Suspense fallback={<FullPageSpinner />}>
                <PAGES.EditEmployee />
              </Suspense>
            } />
            <Route path={URLS.CreateEmployee} element={
              <Suspense fallback={<FullPageSpinner />}>
                <PAGES.CreateEmployee />
              </Suspense>
            } />
            <Route path={URLS.Areas} element={
              <Suspense fallback={<FullPageSpinner />}>
                <PAGES.Areas />
              </Suspense>
            } />
            <Route path={URLS.AreasAddressesList} element={
              <Suspense fallback={<FullPageSpinner />}>
                <PAGES.AreasAddressesList />
              </Suspense>
            } />
            <Route path={`${URLS.AreaDetails}/:id`} element={
              <Suspense fallback={<FullPageSpinner />}>
                <PAGES.AreaDetails />
              </Suspense>
            } />
            <Route path={`${URLS.AddressDetails}/:id`} element={
              <Suspense fallback={<FullPageSpinner />}>
                <PAGES.AddressDetails />
              </Suspense>
            } />
            <Route path={`${URLS.EditArea}/:id`} element={
              <Suspense fallback={<FullPageSpinner />}>
                <PAGES.EditArea />
              </Suspense>
            } />
            <Route path={URLS.CreateArea} element={
              <Suspense fallback={<FullPageSpinner />}>
                <PAGES.CreateArea />
              </Suspense>
            } />
            <Route path={URLS.Files} element={
              <Suspense fallback={<FullPageSpinner />}>
                <PAGES.Files />
              </Suspense>
            } />
            <Route path={`${URLS.FilesDetails}/:id`} element={
              <Suspense fallback={<FullPageSpinner />}>
                <PAGES.FileDetails />
              </Suspense>
            } />
            <Route path={URLS.Projects} element={
              <Suspense fallback={<FullPageSpinner />}>
                <PAGES.Projects />
              </Suspense>
            } />
            <Route path={`${URLS.ProjectDetails}/:id`} element={
              <Suspense fallback={<FullPageSpinner />}>
                <PAGES.ProjectDetails />
              </Suspense>
            } />
            <Route path={`${URLS.ProjectLeads}/:id`} element={
              <Suspense fallback={<FullPageSpinner />}>
                <PAGES.ProjectLeads />
              </Suspense>
            } />
            <Route path={`${URLS.ProjectContracts}/:id`} element={
              <Suspense fallback={<FullPageSpinner />}>
                <PAGES.ProjectContracts />
              </Suspense>
            } />
            <Route path={URLS.CreateProject} element={
              <Suspense fallback={<FullPageSpinner />}>
                <PAGES.CreateProject />
              </Suspense>
            } />
            <Route path={`${URLS.EditProject}/:id`} element={
              <Suspense fallback={<FullPageSpinner />}>
                <PAGES.EditProject />
              </Suspense>
            } />
            <Route path={URLS.Calender} element={
              <Suspense fallback={<FullPageSpinner />}>
                <PAGES.Calender />
              </Suspense>
            } />
            <Route path={URLS.CreateAppointment} element={
              <Suspense fallback={<FullPageSpinner />}>
                <PAGES.CreateAppointment />
              </Suspense>
            } />
            <Route path={`${URLS.EditAppointment}/:id`} element={
              <Suspense fallback={<FullPageSpinner />}>
                <PAGES.EditAppointment />
              </Suspense>
            } />
            <Route path={`${URLS.AppointmentDetails}/:id`} element={
              <Suspense fallback={<FullPageSpinner />}>
                <PAGES.AppointmentDetails />
              </Suspense>
            } />
          </Route>
          {/* public routes */}
          <Route path={URLS.Login} element={
            <Suspense fallback={<FullPageSpinner />}>
              <PAGES.Login />
            </Suspense>
          } />
          <Route path={URLS.ResetPassword} element={
            <Suspense fallback={<FullPageSpinner />}>
              <PAGES.ResetPassword />
            </Suspense>
          } />
          <Route path={URLS.Imprint} element={
            <Suspense fallback={<FullPageSpinner />}>
              <PAGES.Imprint />
            </Suspense>
          } />
          <Route path={URLS.DataProtection} element={
            <Suspense fallback={<FullPageSpinner />}>
              <PAGES.DataProtection />
            </Suspense>
          } />
          <Route path="*" element={<PAGES.NoMatch />} />
        </Routes>
      </div>
    </div>
  )
}

export default App;
