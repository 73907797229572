import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import './CustomButton.scss';

const CustomButton = ({ children, text, icon, className, isLoading, disabled, onClick, ...props }) => {
  return (
    <div className='button-wrapper' style={{zIndex:1}}>
      <Button className={className} disabled={disabled || isLoading} onClick={onClick} {...props}>
        {isLoading ? (
          <Spinner animation="border" size="sm" className='pr-5' />
        ) : (
          icon && (
            <span className='button-icon'>
              <i>{icon}</i>
            </span>
          )
        )}
        {text}
        {children}
      </Button>
    </div>
  );
};

export default CustomButton;
