const URLS = {
    Login: "/login",
    ResetPassword: "/reset-password",
    Imprint: "/Imprint",
    DataProtection: "/data-protection",
    DashBoard: "/dashboard",
    Employees: "/employees",
    EmployeeDetails: "/employee-details",
    EditEmployee: "/employee/edit",
    CreateEmployee: "/create-employee",
    Areas: "/areas",
    AreasAddressesList: "/areas-addresses-list",
    AreaDetails: "/area-details",
    AddressDetails: "/address-details",
    EditArea: "/area/edit",
    CreateArea: "/create-area",
    Files: "/files",
    FilesDetails: "/file-details",
    Projects: "/projects",
    ProjectDetails: "/projects-details",
    ProjectLeads: "/projects-leads",
    ProjectContracts: "/project-contracts",
    CreateProject: "/create-project",
    EditProject: "/project/edit",
    Calender: "/calender",
    CreateAppointment: "/create-appointment",
    EditAppointment: "/appointment/edit",
    AppointmentDetails: "/appointment-details",
}

export default URLS;
